footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: $black-soft;
  height: 10vh;
  padding: 0 10%;
  img {
    display: inline-block;
    height: 2.7rem;
  }
  span,
  a {
    color: $white;
    font-family: "Courier New", Courier, monospace;
    @media screen and (max-width: 1200px) {
      align-self: flex-end;
    margin-bottom: 5%;  
    }
    
  }
  span {
    margin-left: 5%;
    white-space: nowrap;
  }
  .logo__wrapper {
    width: 50%;
  }
}
