.workshops {
  height: 100vh;
//   box-shadow: 0rem 0rem 4rem #333;
  background-color: $black-soft;
  box-shadow: 0rem 0rem 4rem #333;
  padding: 2.4%;
  @media screen and (max-width: 1200px) {
    padding: 0;
    padding-top: 5%;
    height: auto;
    min-height: 100vh;
  }
  &__description {
    &--wrapper {
      position: relative;
      width: 100%;
      height: 100%;
      grid-area: 2 / 1 / 3 / 2;
      overflow-y: auto;
      direction: rtl;
      @media screen and (max-width: 1200px) {
        grid-area: 3 / 1 / 4 / 3;
        direction: ltr;
      }
    }
    position: absolute;
    direction: ltr;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 1;
    transition: 0.5s;
    padding: 7%;
    padding-left: 25%;
    @media screen and (max-width: 1200px) {
      padding-left: 7%;
    }
    &.active {
      opacity: 1;
      z-index: 2;
    }
    h3 {
      text-align: center;
    }
    p {
      font-family: "Open Sans", sans-serif;
      text-align: center;
      margin: 0 auto;
      padding-left: 15%;
      padding-top: 1%;
      color: $white;
      margin-bottom: 2rem;
      @media screen and (max-width: 1200px) {
        padding-left: 0%;
      }
    }
  }

  &__plan {
    margin-top: 6%;
    h3 {
      text-align: left;
      margin-bottom: 3%;
    }
    h2 {
      padding-left: 14%;
      margin-bottom: 1rem;
      margin-top: 1rem;
    }
    ul {
      margin: 0;
      padding-left: 23%;
      list-style: disc;
      li {
        color: $white;
        margin-bottom: 1rem;
        span {
          color: $white;
        }
      }
      
      .subsection {
        margin: 5px;
        padding-left: 10%;
        list-style: circle;
      }
    }
    h2 {
      font-size: 2rem;
      color: $red;
    }
  }
  
  &__grid {
    display: grid;
    grid-template: 10% 90% / 67.5% 32.5%;
    height: 100%;
    @media screen and (max-width: 1200px) {
      grid-template: 1fr 1fr 7fr / 1fr;
      height: auto;
      min-height: 100vh;
    }
    h1 {
      grid-area: 1 / 1 / 2 / 3;
    }
  }
  &__info {
    display: flex;
    color: $red;
    justify-content: space-evenly;
    &--time {
      width: 50%;
      display: flex;
      i {
        display: inline-block;
        height: 3rem;
        width: 3rem;
        margin: auto;
        margin-right: 1%;
        clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 50%, 50% 50%, 50% 0);
        border-radius: 50%;
        background-color: $red;
      }
    }
    h3 {
      width: 50%;
    }
  }

  &__controls {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    grid-area: 2 / 2 / 3 / 3;
    @media screen and (max-width: 1200px) {
      img {
        display: none;
      }
      grid-area: 2/ 1/ 3 /3;
      flex-direction: row;
    }
    button {
      display: block;
      height: 7rem;
      width: 90%;
      cursor: pointer;
      border: 0.5rem solid $red;
      margin-top: 2.2rem;
      font-family: Arial, Helvetica, sans-serif;
      font-weight: 900;
      background-color: $red;
      color: $white;
      transition: 0.3s;
      letter-spacing: 0.15rem;
      padding: 0 5%;
      @media screen and (max-width: 1200px) {
        width: 25%;
        padding: 0;
      }
      &.active {
        color: $red;
        background-color: $white;
      }
      &:active,
      &:focus {
        outline: none;
      }
    }
  }
}
