.consulting {
  min-height: 100vh;
  background-color: $black-soft;
  box-shadow: 0rem 0rem 4rem #333;
  padding: 2.4%;
  @media screen and(max-width: 1200px) {
    padding: 5% 0;
    height: auto;
  }
  &__title {
    grid-area: 1 / 1 / 2 / 5;
    color: $red;
    padding-bottom: 7%;
  }
  &__grid {
    height: 100%;
    display: grid;
    grid-template: 1fr 1fr 3fr / 1fr 1fr 1fr 1fr;
    @media screen and (max-width: 1200px) {
      display: flex;
      flex-direction: column;
    }
    &--subtitle {
      color: $white;
      font-size: 3rem;
      margin-top: 5%;
    }
    p {
      color: $white;
      padding: 5%;
      text-align: justify;
    }
    div {
      @media screen and (max-width: 1200px) {
        &:nth-of-type(1) {
          order: 2;
        }
        &:nth-of-type(2) {
          order: 4;
        }
        &:nth-of-type(3) {
          order: 6;
        }
        &:nth-of-type(4) {
          order: 8;
        }
      }
    }
    img {
      margin: auto;
      @media screen and (max-width: 1200px) {
        &:nth-of-type(1) {
          order: 1;
        }
        &:nth-of-type(2) {
          order: 3;
        }
        &:nth-of-type(3) {
          order: 5;
        }
        &:nth-of-type(4) {
          order: 7;
        }
      }
    }
  }
}
