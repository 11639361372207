header {
  display: flex;
  font-size: clamp(1rem, 1.2vw, 5rem);
  align-items: center;
  justify-content: flex-start;
  height: clamp(9rem, 4.7vw, 20rem);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-image: url(./images/banner.png);
  background-size: 77.5%;
  background-repeat: no-repeat;
  background-position: right top;
  @media screen and (max-width: 1200px) {
    background-image: none;
    display: flex;
    justify-content: center;
  }
}

.header__logo {
  margin-left: 11.15%;
  height: clamp(2.8rem, 1.5vw, 6rem);
  @media screen and (max-width: 1200px) {
    margin: 0;
  }
}

.nav {
  margin-left: auto;
  margin-right: 30vw;
  letter-spacing: 0.7px;
  .nav-list {
    list-style: none;
    display: flex;
    align-items: center;
    font-family: "Open Sans";
    font-weight: bold;
    color: $white;
    &__item {
      cursor: pointer;
      margin-left: 6%;
      &:not(:first-child) {
        margin-left: 7.4%;
      }
    }
  }
  @media screen and (max-width: 1200px) {
    display: none;
  }
}

.burger {
  position: fixed;
  top: 0;
  left: 0;
  margin: 2% 2%;
  display: none;
  height: 4rem;
  width: 4rem;
  z-index: 101;
  @media screen and (max-width: 1200px) {
    display: block;
  }
}
