.contact {
  height: 90vh;
  @media screen and (max-width: 1200px) {
    height: auto;
    h1 {
      margin-top: 5%;
    }
  }

  &__grid {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template: 1fr / 20% 30% 30% 20%;
    @media screen and (max-width: 1200px) {
      display: flex;
      flex-direction: column;
      height: auto;
    }
  }
  &__form {
    // background-color: $slate;
    grid-area: 1 / 2 / 2 / 3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h1 {
      font-size: 5rem;
    }
    form {
      margin-top: 10%;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      @media screen and (max-width: 1200px) {
        padding: 10% 0;
        width: 100%;
      }
      input {
        display: block;
        width: 90%;
        border: none;
        margin-bottom: 5%;
        padding: 2% 5%;
        font-family: Arial, Helvetica, sans-serif;
        font-weight: 900;
        &::placeholder {
          color: #9badc5;
          font-family: Arial, Helvetica, sans-serif;
          font-weight: 900;
        }
      }
      textarea {
        border: none;
        width: 90%;
        resize: none;
        padding: 2% 5%;
        font-family: Arial, Helvetica, sans-serif;
        font-weight: 900;
        &::placeholder {
          color: #9badc5;
          font-family: Arial, Helvetica, sans-serif;
          font-weight: 900;
        }
      }
      button {
        display: block;
        cursor: pointer;
        border: 0.5rem solid $red;
        margin-top: 2.2rem;
        font-family: Arial, Helvetica, sans-serif;
        font-weight: 900;
        background-color: $red;
        color: $white;
        transition: 0.3s;
        letter-spacing: 0.15rem;
        padding: 2%;
        &:disabled {
          cursor: not-allowed;
        }
      }
    }
  }
  &__mail {
    grid-area: 1 / 3 / 2 / 4;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 1200px) {
      padding: 5% 0;
    }
    
    & > img {
        border-radius: 5px;
    }

    & > h2 {
      font-size: 3rem;
      &:first-of-type {
        color: $red;
      }
    }
    a {
      color: black;
      h2 {
        font-size: 3rem;
      }
    }
  }
}
