.development {
  height: 100vh;
  padding: 2.4%;
  @media screen and (max-width: 1200px) {
    height: auto;
    min-height: 10vh;
    padding: 5% 2.4%;
    padding-bottom: 20%;
  }
  &__grid {
    display: grid;
    width: 100%;
    height: 100%;
    grid-template: 15% 85% / 100%;
    h1 {
      grid-area: 1 / 1 / 2 / 2;
    }
    @media screen and (max-width: 1200px) {
      display: block;
    }
  }
  &__subgrid {
    display: grid;
    height: 100%;
    width: 100%;
    grid-template: 50% 50% / 50% 50%;
    @media screen and (max-width: 1200px) {
      display: flex;
      flex-direction: column;
    }
    &--cell {
      background-repeat: no-repeat;
      background-size: 45%;
      padding-top: 2%;
      @media screen and (max-width: 1200px) {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      &.red {
        background-color: $crimson;
      }
      &.blue {
        background-color: $slate;
      }
      p {
        color: $white;
        width: 50%;
      }
      h1 {
        color: $white;
        font-size: clamp(4rem, 1.7vw, 12rem);
      }
      &.left {
        background-position: left center;
        padding-right: 3%;
        h1 {
          text-align: right;
        }
        p {
          margin-left: auto;
          text-align: right;
        }
      }
      &.right {
        background-position: right center;
        padding-left: 3%;
        h1 {
          text-align: left;
        }
        p {
          margin-right: auto;
          text-align: left;
        }
      }
      &.left,
      &.right {
        @media screen and (max-width: 1200px) {
          padding: 5%;
          h1 {
            text-align: center;
            font-size: 2rem;
          }
          p {
            margin-left: 0;
            margin-right: 0;
            text-align: center;
            width: 100%;
          }
        }
      }
      &.top.left {
        background-image: url("./images/cloud.png");
        @media screen and (max-width: 1200px) {
          background-image: none;
          order: 1;
        }
      }
      &.top.right {
        background-image: url("./images/webapps.png");
        @media screen and (max-width: 1200px) {
          background-image: none;
          order: 2;
        }
      }
      &.bottom.left {
        background-image: url("./images/mobileapps.png");
        @media screen and (max-width: 1200px) {
          background-image: none;
          order: 4;
        }
      }
      &.bottom.right {
        background-image: url("./images/devops.png");
        @media screen and (max-width: 1200px) {
          background-image: none;
          order: 3;
        }
      }
    }
  }
}
