.about {
  min-height: 100vh;
  background-image: url(./images/about.png);
  background-size: 55.5%;
  background-repeat: no-repeat;
  background-position: left center;
  @media screen and (max-width: 1200px) {
    background-image: none;
  }

  &__content {
    margin-top: 2%;
    width: 55%;
    margin-left: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    @media screen and (max-width: 1200px) {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    h2 {
      margin-bottom: 2rem;
    }
    p {
      width: 60%;
      padding-left: 6%;
      @media screen and (max-width: 1200px) {
        width: 100%;
        padding: 6%;
      }
    }
  }
}
