.landing {
  background-image: url(./images/banner.png);
  background-repeat: no-repeat;
  background-position: right top;
  background-size: 77.5%;
  height: 115vh;
  display: flex;
  justify-content: flex-start;
  @media screen and (max-width: 1200px) {
    background-image: none;
  }
  &__credo {
    width: 43%;
    margin-top: 25%;
    margin-left: 6%;
    letter-spacing: -2.7px;
    line-height: 1.5;
    text-align: center;
    font-size: clamp(4rem, 2.5vw, 12rem);
    font-family: "Ubuntu", serif;
    font-weight: 700;
    @media screen and (max-width: 1200px) {
      width: 100%;
      margin-left: 0;
    }
  }
}
