//? set your brand colors

$white: #f3f7f3;

$light-grey: #fafafa;
$grey: #d7d7d7;

$black-soft: #211621;
$black: #09090e;

$blue: #2c638f;
$pink: #fff0f5;
$yellow: #fffacd;
$red: #f06658;
$crimson: #b93e51;
$slate: #8392a6;
//? override for project clarity

$color-primary: $blue;
$color-secondary: $pink;
$color-tertiary: $yellow;
