*::selection {
  background: $pink;
}

body {
  font-size: calc(10px + (16 - 10) * ((100vw - 300px) / (1920 - 300)));
}

html,
body {
  height: 100%;
  background-color: $white;
}

body {
  width: 100%;
  min-height: 100%;
  margin: 0;
}

.wrapper {
  max-width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

p {
  margin: 0;
  font-size: clamp(1rem, 0.9vw, 4rem);
}

a {
  text-decoration: none;
  color: $blue;
}

img {
  display: block;
}

ul {
  padding: 0;
  list-style: none;
}

.container {
  width: 75%;
  margin: 0 auto;
  @media screen and (max-width: 1200px) {
    width: 100%;
  }
}

.red {
  color: $red;
}

h1 {
  color: $red;
  text-align: center;
  font-family: "Open Sans", sans-serif;
  font-size: clamp(8rem, 3vw, 16rem);

  font-weight: 500;
  @media screen and (max-width: 1200px) {
    font-size: 4rem;
  }
}

h2 {
  font-family: "Open Sans", serif;
  font-size: clamp(4rem, 1.7vw, 12rem);
  @media screen and (max-width: 1200px) {
    font-size: 3rem;
  }
}

h3 {
  color: $red;
  font-size: 3rem;
  font-weight: 500;
  font-family: "Open Sans", sans-serif;
}

p {
  font-family: 'Raleway';
  line-height: 1.5;
}

.nav-mobile {
  position: fixed;
  width: 100vw;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  pointer-events: none;

  &.active {
    pointer-events: all;
  }
  &-list {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    &__item {
      background: black;
      color: white;
      width: 100%;
      text-align: center;
      height: 10%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 5%;
      font-weight: bold;
      transform: translateX(-100%);
      transition: transform 0.5s;
      &.active {
        transform: translateX(0%);
      }
      &.closed {
        transform: translateX(-100%);
      }
      &:nth-of-type(1) {
        transition-delay: 0;
      }
      &:nth-of-type(2) {
        transition-delay: 0.1s;
      }
      &:nth-of-type(3) {
        transition-delay: 0.2s;
      }
      &:nth-of-type(4) {
        transition-delay: 0.3s;
      }
      &:nth-of-type(5) {
        transition-delay: 0.4s;
      }
    }
  }
}

.mobile-only {
  @media screen and (min-width: 1201px) {
    display: none;
  }
  max-width: 40%;
  margin: 5% 0;
}

.clear-left-padding {
    padding-left: 0 !important;
}
