//! import google fonts
// @import url('https://fonts.googleapis.com/css?family=Libre+Baskerville:400,400i,700');
// OR use @font-face like below

//! import a custom font below
@font-face {
  font-family: "Libre-Baskerville-Reg";
  font-display: fallback;
  font-style: normal;
  src: local("Libre Baskerville"), local("LibreBaskerville-Regular"),
    url("fonts/libre-baskerville-v5-latin-regular.woff2") format("woff2"),
    url("fonts/libre-baskerville-v5-latin-regular.woff") format("woff");
}

// @font-face {
//   font-family: "Open Sans";
//   font-display: fallback;
//   font-style: normal;
//   src: url("fonts/opensans-regular-webfont.woff2") format("woff2"),
//     url("fonts/opensans-regular-webfont.woff") format("woff");
// }

// @font-face {
//   font-family: "Open Sans";
//   font-display: fallback;
//   font-style: normal;
//   font-weight: bold;
//   src: url("fonts/opensans-bold-webfont.woff2") format("woff2"),
//     url("fonts/opensans-bold-webfont.woff") format("woff");
// }

// @font-face {
//   font-family: "Playfair Display";
//   font-display: fallback;
//   font-style: normal;
//   font-weight: black;
//   src: url("fonts/PlayfairDisplay-Black.woff2") format("woff2"),
//     url("fonts/PlayfairDisplay-Black.woff") format("woff");
// }

@font-face {
  font-family: "Bodoni MT";
  font-display: fallback;
  font-style: normal;
  src: url("fonts/BODR.woff2") format("woff2"),
    url("fonts/BODR.woff") format("woff");
}

//! set font variables
$font-heading: "Libre-Baskerville-Reg", Fallback, "Georgia", serif;
$font-body: "Open Sans", sans-serif;

html {
  font-size: 62.5%; // 1rem = 10px, 3.5rem = 35px;
}

body {
  font-family: $font-body;
  font-size: 1.6rem;
  color: $black;
}

h1 {
  font-family: $font-heading, serif;
  font-size: 1.7rem;
  font-weight: 400;
}
